path.slice{
  stroke-width:5px;
  /*fill: url(#area-gradient);*/
}
   
polyline{
  opacity: .3;
  stroke-width: 2px;
  fill: none;
}
   
.legend {
  font-size: 12px;
}
   
rect {
  cursor: pointer;
  stroke-width: 2;
}

rect.disabled {
  fill: transparent !important;
}

.axis text {
  font: 10px sans-serif;
}
   
.axis path,
.axis line {
  fill: none;
  stroke: #b3c6da;
  stroke-width: 1px;
  shape-rendering: crispEdges;

}

.grid .tick {
  stroke: #D8D8D8;
  stroke-opacity: 0.5;
  shape-rendering: crispEdges;
}

.grid path {
  stroke-width: 0;
}
   
.bar:hover {
  fill : #000000;
}

.d3-tip {
    z-index: 1000 !important;
  line-height: 0;
  font-weight: bold;
  font: 14px sans-serif;
  padding-top: 8px;
  padding-bottom: 6px;
  padding-left: 7px;
  padding-right: 7px;
  background: #F8F8F8;
  color: #000000;
  border: 2px;
  border-style: solid;
  border-color: grey;
  border-radius: 0px;
  box-shadow: 1px 1px 3px #434343;
}
   
/* Creates a small triangle extender for the tooltip */
.d3-tip:after {
  /*box-sizing: border-box;*/
  display: inline;
  font-size: 10px;
  width: 100%;
  line-height: 1;
  color: rgba(0, 0, 0, 0.8);
  /* content: "\25BC"; */
  color:gray;
  position: absolute;
  min-width: 100px;
  text-align: center;
}
   
/* Style northward tooltips differently */
.d3-tip.n:after {
  margin: 2px 0 0 0;
  top: 100%;
  left: 0;
}

.ui-slider .ui-slider-handle{
  height: 15px;
  width: 15px;
  margin-top: -0.4px; /*1.5px*/
  background-color: #4da2ff ;  /*56973a*/
  position:absolute;
  border-radius: 0px;
  border-style:solid; 
  border-color: #005ec4;
  border-width: 1px;
}

.ui-slider-horizontal {
  height: 10px;
  width: 200px;
  border-color: #dbdbdb;
  border-radius: 0px;
}

.ui-slider-range{
  background-color: #001c3b;
}

body {
    font: 10px sans-serif;
    background-color: #ffffff;
}

/* .state{
    fill: none;
    stroke: #a9a9a9;
    stroke-width: 1;
  }
  .state:hover{
    fill-opacity:0.5;
  } */

/*svg .data-point {
    stroke: blue;
    stroke-width: 1.5px;
    fill: blue;        
}

svg .data-point-text {
    font-family: sans-serif;
    font-size: 12px;
    fill: red;
}*/

/*svg .axis path, 
svg .axis line {
    fill: none;
    stroke: black;
}*/

/*svg .axis text {
    font-family: sans-serif;
    font-size: 12px;
}*/
.line {
  fill: none;
  stroke: #e5a810; /*005a4e*/ /*e5a802*/
  stroke-width: 1.5px;
  
}
.area {
  /*fill: #00d0b5;*/
  stroke: #007aff;
  stroke-width: 0px;
  /*opacity: 0.6;*/
  
}
.circle {
    fill: #e5a802;
    stroke: #e5a802; /*001f1b ============ 00f7d7*/
    stroke-width: 1.0px;
}
/*.axis path,
.axis line {
    fill: none;
    stroke: #636363;
    stroke-width: 1;
    shape-rendering: crispEdges;
}*/

/*.grid .tick {
    stroke: #909090; 
    stroke-opacity: 0.3;
    shape-rendering: crispEdges;
}
.grid path {
          stroke-width: 0;
}*/
/*.content{
    height: 50px;
    width: 100%;

}*/
.demo li, .demo li { margin: 0 5px 5px 5px; padding: 5px; font-size: 1.2em; width: 120px; list-style-type:none;}

.bullet { font: 10px sans-serif; }
/*.bullet .marker { stroke: #969A9A; stroke-width: 2px; }*/
.bullet .tick line { stroke: #666; stroke-width: .5px; }
/*.bullet .range.s0 { fill: #d2d2d2; }*/
.bullet .range.s1 { fill: #d2d2d2; }
.bullet .range.s2 { fill: #d2d2d2; }
.bullet .measure.s0 { fill: #969A9A; }
/*.bullet .measure.s1 { fill: steelblue; }*/
.bullet .title { font: 12px Roboto; }
.bullet .subtitle { fill: #999; }
.svgDiv{
  height: 90%;
  width: 100%;
  overflow: hidden;
  position: absolute;
  top:25px;
}
.svgPanelClassForMap{
  height:100%;
  width:100%;
  overflow: hidden;
}
.hoverMap:hover {
  fill-opacity:0.5;
}
.capital{
  /* margin: 0; */
  text-anchor:middle;
  font-family:Verdana,sans-serif;
  font-weight:Bold;
  font-size:12px;
  font-style:normal;
  /* text-transform:capitalize; */
  /* display:inline-block; */
  text-transform:lowercase;
}

